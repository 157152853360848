import { Link } from "react-router-dom";
import { Mail, Package, PackageSearch, Store } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";

export default function SupportPage() {
  return (
    <div className="container mx-auto px-4 py-16">
      <div className="mx-auto max-w-3xl space-y-12">
        <div className="space-y-4 text-center">
          <h1 className="text-4xl font-bold tracking-tight">
            How can we help?
          </h1>
          <p className="text-lg text-muted-foreground">
            Choose from the options below to get the support you need.
          </p>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Customer Support</CardTitle>
            <CardDescription>
              Get help with your orders and general inquiries
            </CardDescription>
          </CardHeader>
          <CardContent className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <Package className="h-5 w-5" />
                <Link to="/cart">
                  <h3 className="font-semibold">Place an Order</h3>
                </Link>
              </div>
              <p className="text-sm text-muted-foreground">
                Need help placing your order? We're here to assist you.
              </p>
              <Button asChild>
                <Link to="/cart">Place Order</Link>
              </Button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <PackageSearch className="h-5 w-5" />
                <Link to="/ordertracking">
                  <h3 className="font-semibold">Track Your Order</h3>
                </Link>
              </div>
              <p className="text-sm text-muted-foreground">
                Check the status and location of your order.
              </p>
              <Button asChild>
                <Link to="/ordertracking">Track Order</Link>
              </Button>
            </div>
            <div className="sm:col-span-2">
              <Separator className="my-4" />
              <div className="flex flex-col items-center gap-4 text-center">
                <div className="flex items-center gap-2">
                  <Mail className="h-5 w-5" />
                  <h3 className="font-semibold">Email Support</h3>
                </div>
                <p className="text-sm text-muted-foreground">
                  Have a different question? Our support team is here to help.
                </p>
                <Button variant="outline" asChild>
                  <a href="mailto:support@spockal.com">
                    Email Us at support@spockal.com
                  </a>
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Become a Merchant</CardTitle>
            <CardDescription>
              Start selling your products on our platform
            </CardDescription>
          </CardHeader>
          <CardContent className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <Store className="h-5 w-5" />
                <h3 className="font-semibold">Join Today</h3>
              </div>
              <p className="text-sm text-muted-foreground">
                Ready to grow your business? Start selling with us today.
              </p>
              <Button asChild>
                <a href="https://stores.spockal.com/auth/join" target="_blank">
                  Get Started
                </a>
              </Button>
            </div>
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <Mail className="h-5 w-5" />
                <h3 className="font-semibold">Merchant Support</h3>
              </div>
              <p className="text-sm text-muted-foreground">
                Questions about becoming a merchant? Contact our team.
              </p>
              <Button variant="outline" asChild>
                <a href="mailto:merchants@spockal.com">
                  Contact Us at merchants@spockal.com
                </a>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
